<template>
    <div id="pageHeaders" :class="`clearMe_${lang.langname} pa-2 text-start`">
        <div :class="`timeNow_${lang.langname} text-center`" v-if="$store.getters['state'].group_id != 9">
            {{ timeNow }}</div>
        
        <!-- <img class="imglogo" style="" :src="`${publicPath}/header-logo-bg.png`"> -->
        <div class="gbackground m-2 mt-0" style="display:none;padding-top:10px !important;">
               &nbsp; {{ sitetitle }}
               <span class="logoutclass">
                   <i @click="$cookies.remove($COOKIEPhase),$cookies.remove($store.state.COOKIEPhase),cook = 'null', $router.push({name: 'Logout'})" class="fas fa-sign-out-alt" style="font-size:1.7em;cursor:pointer;"></i>
               </span>
        </div>
            <v-card
                v-if="$store.getters['state'].group_id != 9"
                class="d-flex  text-center"
                color="#d7b100"
                >
                    <v-card
                        v-for="(item,index) in topMenuO"
                        :key="index"
                        class="pa-1"
                        style="margin-inline-end:5px;padding:25px 1px !important; border:1px solid #000"
                        outlined
                        :style="`background:`+item.bgcolor+` !important;font-weight:bold;width:120px;color:`+item.color"
                        @click="changeMenu(item.index,item.href,item.to)"
                    >
                        {{item.text}}
                    </v-card>
                    <v-card v-if="showTraining"
                        class="pa-1"
                        style="margin-inline-end:5px;padding:25px 1px !important;"
                        outlined
                        :style="`background:black !important;font-weight:bold;width:120px;color:#FFF`"
                        v-b-toggle.view_help
                    >
                        {{ lang.training }}
                    </v-card>
                    <v-card v-if="showExit"
                        class="pa-1"
                        style="margin-inline-end:5px;padding:25px 1px !important;"
                        outlined
                        :style="`background:red !important;font-weight:bold;width:120px;color:#FFF`"
                        @click="gotoHome(), $router.push({path: '/logout'})"
                    >
                        {{ lang.logout }}
                    </v-card>
            </v-card>
            <samHelpVue />
            <v-btn v-b-toggle.renewalPage style="display:none;" id="showMessage">Open page</v-btn>
            <renewalPage />
    </div>
</template>

<script>
import axios from 'axios'
import samHelpVue from '@/components/samHelp.vue'
import renewalPage from '@/components/crm/renewalPage.vue'
import FloatButtonVue from './FloatButton.vue'
export default {
    components: {samHelpVue,renewalPage},
    data() {
        return {
           showMessage: false,
           sitetitle: ' ',
           cook: '',
           publicPath: process.env.BASE_URL+'img/',
           showHeader: true,
           topMenuO: [],
           showExit: false,
           showTraining: false,
           timeNow: '',
           defMenu: 'erp',
           stopClick: false,
           count: 0,
        }
    },
    created() {
        // if(this.showHeader){
            this.getSettings();
            this.getUser();
            setInterval(() => {this.drawMenu()},50)
            setTimeout(() => {this.showExit = false;this.showExit = true;},70)
            setTimeout(() => {this.showTraining = false;this.showTraining = true;},70)
            this.drawMenu()
            setInterval(() => {this.getTimeNow()},1000)
            this.startIt();
        // }
    },

    methods: {
        startIt()
        {
            let t = setInterval(() => {this.showIt(t);},1000)
        },
        showIt(t){
            this.count =parseFloat(this.count) + parseFloat(1);
            if(this.showMessage && !this.stopClick){
                console.log(this.count);
                document.getElementById('showMessage').click()
                this.stopClick = true
            }
        },
        getTimeNow(){
            const date = new Date();
            let d = date.getDate();
            d = d < 10 ? "0"+d : d;
            let m = date.getMonth() + 1;
            m = m < 10 ? "0"+m : m;
            const y = date.getFullYear();
            let h = date.getHours();
            let dm = "AM";
            if(h == 0) h = 12;
            else if(h < 10) h = "0"+h;
            else if(h>12){h = parseInt(h) - parseInt(12);dm = "PM"}
            else if(h == 12) {dm = "PM"}

            let min = date.getMinutes();
            min = min < 10 ? "0"+min : min;
            let sec = date.getSeconds();
            sec = sec < 10 ? "0"+sec : sec;
            h = h < 10 ? "0"+h : h;
            this.timeNow = d+"-"+m+"-"+y+" "+h+":"+min+":"+sec+" "+dm;
        },
        gotoHome(){
            localStorage.setItem('currentMenu',1);
        },
        changeMenu(index ,href,to){
            localStorage.setItem('currentMenu',index);
            if(href != this.$route.path){
                if(this.$store.state.licenseType.isCar){
                    this.$router.push({path: to})
                }else{
                    this.$router.push({path: href})
                }
                
            }
        },
        getSettings(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getSettings");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                console.log("response",response.data)
                console.log(res.results.d.showImg);
                if(res.results.d.showImg == 1){
                    this.showMessage = true;
                }else{
                    this.showMessage = false;
                }
                
                if(res.error.number != 200){
                    this.$router.push('/logout');
                    return false;
                }
                this.defMenu = res.results.avmenu.activemenu;
                this.$store.state.settings = res.results.data;
                
            })
        },
        getUser(){
            const post = new FormData();
            post.append('defBranch',localStorage.getItem('defBranch'));
          post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("type" , "getUser");
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[all]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                if(res.results.data.length != 0){
                    this.$store.state.group_id = res.results.data.group_id;
                }
            })
        },
        drawMenu(){
            this.topMenuO = this.TopMenu
        }
    },
    computed: {
        lang: {
            get: function(){
                if(this.$cookies.get(this.$store.state.LangCooki) != null){
                    if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                        return this.$store.state.lang.en;
                    }else{
                        return this.$store.state.lang.ar;
                    }
                }
                else if(this.$store.state.deflang == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            },
            set: function(newValue){
                return newValue
            }
        },
        TopMenu:  function(){
            let t = [];
            const menu = this.$store.getters['state'].menu;
            // const license = this.$store.getters['state'].licenseType.activemenu;
            const license = this.$store.state.licenseType.activemenu;
        
            // let currentMenu = menu[this.defMenu]; 
            let currentMenu = menu[license]; 
            if(typeof currentMenu === 'undefined')  return false;
            Object.values(currentMenu).forEach(value =>{
                
                if(this.lang.langname == 'ar'){
                    value.main.text = value.main.arname
                }else{
                    value.main.text = value.main.enname
                }
                const cmenu = localStorage.getItem('currentMenu');
                
                if(cmenu == value.main.index){
                    value.main.bgcolor = '#ffffff';
                    value.main.color = '#000000';
                }else if(cmenu == null){
                    if(value.main.index == 1){
                        value.main.bgcolor = '#ffffff';
                        value.main.color = '#000000';
                        localStorage.setItem('currentMenu',1);
                        this.changeMenu(1,value.main.href)
                    }
                }else{
                    value.main.bgcolor = '#000000';
                    value.main.color = '#ffffff';
                }
                // console.log(this.$store.getters['state'].group_id);
                for(let i =0; i< value.main.groupid.length;i++){
                    if(value.main.groupid[i] == this.$store.getters['state'].group_id){
                        t.push(value.main)
                    }
                }
            });
            
            

            // // console.log("topMenu",t);
            return t;
        },
        groupid: function() {
            return this.$store.state.group_id;
        },
        license: function(){
            return this.$store.getters['state'].licenseType.activemenu
        }
    },
    
}
</script>
<style>
.helpicon_ar{
    position:absolute;
    left: 10px;
    top:50px;
    width:80px;
    display:flex;
    justify-content: center;
    justify-items: center;
    background:#000;
    color:#fff;
    border-radius: 25px;
    padding:2px;
    z-index: 1000;
    cursor: pointer;
}
.helpicon_en{
    position:absolute;
    right: 10px;
    top:30px;
    width:80px;
    display:flex;
    justify-content: center;
    justify-items: center;
    background:#000;
    color:#fff;
    border-radius: 25px;
    padding:2px;
    z-index: 1000;
    cursor: pointer;
}
.gbackground{
    position: relative;
    z-index: 10;
    background: rgb(215,177,0);
    background: linear-gradient(
    -45deg, rgba(215,177,0,1) 0%, 
    #d7b100 88%, rgba(0,0,0,1) 88%, 
    rgba(0,0,0,1) 89%, rgba(215,177,0,1) 89%, 
    rgba(215,177,0,1) 91%, rgba(0,0,0,1) 91%, 
    rgba(0,0,0,1) 92%, rgba(215,177,0,1) 92%, 
    rgba(215,177,0,1) 94%, rgba(0,0,0,1) 94%, 
    rgba(0,0,0,1) 95%, rgba(215,177,0,1) 95%, 
    rgba(215,177,0,1) 100%
    );
    padding:0px !important;
}
.logoutclass{
    color:#FFF;
    position: absolute;
    left:5px;
    top: 10px;
}
.clearMe_ar{
    position: relative;
    background: rgb(215,177,0);
    direction:rtl;
    color:#FFF;
    padding:2px 10px 10px 5px;
}
.clearMe_en{
    position: relative;
    background: rgb(215,177,0);
    direction:ltr;
    color:#FFF;
    padding:2px 10px 10px 5px;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
.imglogo{
    position: absolute;
    top:2px;
    left:3px;
    z-index: 1000;
    height:85px;
    border: 2px solid #000;
}
td, th{
    border:1px solid #000 !important;
}
.timeNow_ar{
    direction: ltr;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background:#000;
    color:gold;
    font-size:1rem;
    position: absolute;
    top:30px;
    left: 30px;
    padding:10px;
    /* border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; */
    width:200px;
    height: 30px;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.timeNow_en{
    direction: ltr;
    display:flex;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: center;
    background:#000;
    color:gold;
    font-size:1rem;
    position: absolute;
    top:30px;
    right: 30px;
    padding:10px;
    /* border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px; */
    width:200px;
    height: 30px;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
</style>